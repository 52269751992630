@import url(//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css);

@import url('./../fonts/MyFontsWebfontsKit/MyFontsWebfontsKit.css');

@import url("./../fonts/MyFontsWebfontsKit/MyFontsWebfontsKit.css");
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";


/*SCSS imports*/
@import "core/variables";
@import "core/mixins";

:root {
    /* Not my favorite that line-height has to be united, but needed */
    --lh: 1.4rem;
}

/* Default styles */
html,body {
    width: 100%;
    height: 100%;
    background: #F7FAFC;
}
body {
    font-family: 'Nexa-Regular';
    font-weight: 400;
}
 /*.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6,strong{
   text-transform: capitalize;
}*/
.capitalizeNew{
    text-transform: capitalize;
}
.form-group,.search-icon {
    .form-control:focus {
        background: $light-gray;
        box-shadow: 0 0 0 0.1rem rgba(0,123,255,.25);
    }
}
.icon-input.search-icon {
    .form-control{
        width: 25vw;
      }
}
input.form-control {
    background: $light-gray;
    border: none;
    padding: 11px 12px;
    font-size: 13px;
}
select.form-control {
    font-size: 13px;
}
textarea.form-control {
    background: $light-gray;
    border: none;
    padding: 11px 12px;
    font-size: 13px;
    min-height: 200px;
}
.e-control-wrapper{
    width: 100%;
    border: none !important;
    background: #f4f4f4 !important;
    padding: 6px 12px !important;
    border-radius: .25rem !important;
  }
  .e-input-focuse{box-shadow: none !important;}
  
  .e-input-in-wrap::after, .e-input-in-wrap::before{
    box-shadow: none !important;
  }
.disabled{
    opacity: 0.5;
    pointer-events: none;
  }
.custm_check {
    background: $light-gray;
    border-radius: 5px;
    padding: 5px 8px 5px 32px;
    margin-bottom: 5px;
    label {
        font-size: $font-mediumx;
    }
}
.checkBoxButton label.noncheckList:before{
    display:none ;
}
.blue_label {
    font-size: $font-small;
    color: $blue;
    font-family: 'Nexa-Heavy';
    text-transform: uppercase;
    margin-bottom: 5px;
}
.blue_label_centor{
    font-size: 15px;
    color: #58B5C9;
    font-family: 'Nexa-Heavy';
    text-transform: uppercase;
    margin-bottom: 5px;
    text-align: center;
    padding: 5px;
    display: block;
}
.banner_grid{
    background: #f4f4f4;
    padding: 20px 55px 55px 55px;
}
.icon-input {
    position: relative;
    &::before {
        width: 39px;
        height: 40px;
        position: absolute;
        left: 0px;
        top: 0px;
        content: '';
        background-position: center;
        background-repeat: no-repeat;
    }
    .form-control {
        padding-left: 39px;
    }
}
.icon-input.user-icon::before {
    background-image: url(./../images/icon_user.svg);
}
.icon-input.password-icon::before {
    background-image: url(./../images/icon_password.svg);
}
.icon-input.search-icon::before {
    background-image: url(./../images/magnifying-glass.svg);
}

.icon-input.password-icon {
    input {
        font-size: 24px;
        padding-top: 0px;
        padding-bottom: 2px;
        letter-spacing: 12px;
        font-weight: 300;
        font: 30px droid sans mono, consolas, monospace;
        line-height: 0px;
        height: 40px;
        :-webkit-autofill,
        :-webkit-autofill:hover, 
        :-webkit-autofill:focus{
            font-size: 24px;
            padding-top: 0px;
            padding-bottom: 2px;
            letter-spacing: 12px;
            font-weight: 300;
            font: 30px droid sans mono, consolas, monospace;
            line-height: 0px;
            height: 40px;
        }
    
    }
    ::placeholder { 
        color: #000;
        opacity: 1; 
      }
      :-ms-input-placeholder { 
        color: #000;
      }
      ::-ms-input-placeholder { 
        color: #000;
      }
}

.link-text-small {
    font-family: 'Nexa-RegularItalic';
    font-size: $font-basex;
    color: $blue;
    text-decoration: none;
}
.link-text-small:hover,.link-text-small:focus {
    color: $blue;
}
.more_icon {
    width: 24px;
    height: 18px;
    display: inline-block;
    background-image: url(./../images/more.svg);
    background-repeat: no-repeat;
}

.white_panel {
    background: $white;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 5px;
}
.form_pading {
    padding: 35px 45px 35px 45px;
}
.btn-submit,a.btn-submit:not([href]):not([tabindex]) {
    background: $blue;
    color: $white;
    border:none;
    border-radius: 3px;
    font-family: 'Nexa-Heavy';
    font-size: $font-mediumx;
    padding: 8px 15px;
    margin: 10px 0px;
    min-width: 110px;
    text-align: center;
}
.btn-bordered,a.btn-bordered:not([href]):not([tabindex]) {
    background: $white;
    color: $blue;
    border: 1px solid $blue; 
    border-radius: 3px;
    font-family: 'Nexa-Heavy';
    font-size: $font-mediumx;
    padding: 8px 15px;
    margin: 10px 0px;
    min-width: 110px;
    text-align: center;
}

.btn-back {
    background-color: $white;
    background-image: url(../images/keyboard-left-arrow-button.svg);
    background-repeat: no-repeat; 
    background-position: 10px;
    color: $blue;
    border: 1px solid $blue;
    border-radius: 5px;
    font-family: 'Nexa-Heavy';
    font-size: $font-mediumx;
    padding: 8px 35px;
    margin: 10px 0px;
    min-width: 110px;
    text-align: center;
    text-decoration: none !important;
    display: inline-block;
}
.groupBox.col-md-3 {
    margin: 10px;
    background: #f2f2f2;
    border-radius: 3px;
}
.customCheckBoxs{
    .checkBoxButton {
        label{
            padding: 1px 0px 1px 0px;
            /*text-transform: capitalize;*/
        }
        label:before{
            right: 0;
            left: unset !important;
        }
        label:before, label:after{
            border-radius: 16px;
            width: 20px;
            height: 20px;
        }

        input[type="checkbox"]:checked + label:before{
            background-position: 3px 5px;
        }
    }
    
}
.group_panel {
    border: 1px solid #D9D9D9;
    padding: 25px;
    border-radius: 5px;
}
// .Toastify__toast--info {
//     background: #58b5c9 !important;
// }
/*non login pages styles */

.non--login {
    @include linear-gradient(180deg, $orange 0%, $pink 100%);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top:0 ;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
}

.login-form {
    width: 348px;
    max-width: 100%;
    padding: 10px;
    .logo_img {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        justify-content: center;
        img  {
            width: 66px;
            padding-right: 10px;
        }
        h1 {
            font-size: $font-jumbo;
            font-family: 'Nexa-RegularItalic';
            margin: 0px;
            span {
                font-family: 'Nexa-BlackItalic';
            }
        }
    }
    .login-form--inner {
        background: $white;
        padding: 35px 37px 50px 37px;
        border-radius: 5px;
        h2 {
            font-size: $font-large;
            text-align: center;
            font-family: 'Nexa-HeavyItalic';
            color: $xxdark-gray;
        }
        .form-group {
            margin-bottom: 5px;
            label {
                font-size: $font-small;
                color: $blue;
                font-family: 'Nexa-Heavy';
                text-transform: uppercase;
                margin-bottom: 5px;
            }
            
        }
        .login_btn {
            width: 100%;
            background: $blue;
            color: $white;
            border:none;
            border-radius: 5px;
            font-family: 'Nexa-Heavy';
            font-size: $font-mediumx;
            padding: 8px 15px;
            margin: 10px 0px;
            display: inline-block;
            text-align: center;
        }
        .check-remember {
            label {
                font-family: 'Nexa-RegularItalic';
                font-size: $font-basex;
                color: $xdark-gray;
            }
            input {
                margin-top: 2px;
            }
            .checkBoxButton {
                background: #fff;
            }
            .checkBoxButton label:before, .checkBoxButton label:after  {
                border-radius: 50% !important;
            }
        }

        .invalid_login {
            background: $red-light;
            border-radius: 5px;
            padding: 10px 10px 10px 43px;
            position: relative;
            margin: 10px 0px;
            &::before {
                content: '';
                width: 23px;
                height: 23px;
                background-color: $red-dark;
                background-image: url('./../images/cancel.svg');
                background-position: center;
                background-repeat: no-repeat;
                position: absolute;
                left: 10px;
                top: 10px;
                border-radius: 50px;
            }
            p {
                margin: 0px;
                font-family: 'Nexa-Bold';
                color: $red;
                font-size: $font-basexx;
                line-height: 12px;
                span {
                    font-family: 'Nexa-Regular';
                    font-size: $font-small;
                }
            }
        }

    }
    
}
.login-form.alert-messages {
    width: 500px;
    max-width: 100%;
    p {
        font-size: 12px;
    }
}

.reset-pin-notification {
    width: 450px;
    max-width: 100%;
    padding: 20px 25px;
    color: $white;
    font-size: $font-mediumxx;
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    text-align: center;
    background: $blue;
    border-radius: 5px;
    margin: 0 auto;
}

.footer {
    position: absolute;
    width: 100%;
    bottom: 0px;
    padding: 10px;
    text-align: center;
    font-size: $font-basexx;
    color: $white;
}


/*non login pages styles ends*/


/* Inner pages Layout Style starts */
.page_container {
    width: 100%;
    overflow-x: hidden;
    background: #F7FAFC;
    height: 100%;

    /*Header styles starts*/
    header {
        padding: 4px 10px 4px 85px;
        background: $white;
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        z-index: 999;
        .text-logo {
            h1 {
                font-size: $font-large;
                font-family: 'Nexa-RegularItalic';
                margin: 0px;
                padding-top: 14px;
                span {
                    font-family: 'Nexa-BlackItalic';
                }
            }
        }
    }
    /*Header styles ends*/

    /*Nav Style starts*/
    nav.main_nav {
        position: fixed;
        width: 75px;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 999;
        @include linear-gradient(180deg, $orange 0%, $pink 100%);
        .nav-logo {
            text-align: center;
            padding-top: 15px;
            img {
                max-width: 36px;
            }
        }
        .nav-menu {
            padding-top: 37px;
            ul {
                list-style: none;
                padding: 0px;
                margin: 0px;
                li {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 15px;
                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 55px;
                        height: 51px;
                        border-radius: 5px;
                    }
                    a.active,a:active,a:focus,a:hover {
                        background: $orange-dark;
                    }
                    span {
                        position: absolute;
                        left: 100%;
                        width: 210px;
                        padding-left: 20px;
                        line-height: 75px;
                        background: #fff;
                        box-shadow: 0px 0px 6px #00000029;
                        font-family: 'Nexa-BlackItalic';
                        color: #3B3B3B;
                        text-transform: uppercase;
                        font-size: 11px;
                        display: none;
                    }
                    span.long{
                        width: 300px;
                    }
                    &:hover {
                        span {
                            display: block;
                        }
                    }
                }
            }
        }

        .nav-footer {
            text-align: center;
            padding-bottom: 23px;
            position: absolute;
            width: 100%;
            bottom: 0;
            p {
                margin-bottom: 0px;
                color: #fff;
                font-size: $font-base;
                line-height: 16px;
                span {
                    display: block;
                    font-size: $font-large;
                }
            }
        }
    }
    .show>.btn-primary.dropdown-toggle:focus {
        box-shadow: none; 
        background: none;
        border: none;
    }
    .user_dropdown  {
        outline: none;
        display: inline-block;
        .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
            background: none  !important; 
            border:none  !important; 
            outline: none;
            box-shadow: none !important; 
            color: #7B7A7A;
        }
        .dropdown-toggle {
            background: none;
            border:none;
            color: #7B7A7A;
            position: relative;
            font-family: 'Nexa-BoldItalic';
            font-size: 12px;
            outline: none;
            box-shadow: none;
            &::before {
                content: '';
                position: absolute;
                top: 25px;
                left: 40px;
                width: 15px;
                height: 15px;
                background: white;
                border-radius: 50%;
                box-shadow: 1px 1px 4px 0px #888585;
            }
            &::after {
                left: 41px;
                top: 31px;
                position: absolute;
                border-top: .3em solid #58B5C9;
            }
            &:hover, &:focus, &:active,&:visited,&:focus-visible {
                background: none  !important; 
                border:none  !important; 
                outline: none;
                box-shadow: none !important; 
                color: #7B7A7A;
            }
            img {
                width: 38px;
                height: 38px;
                background: #fff;
                border: 1px solid #58b5c9;
                border-radius: 50%;
                padding: 2px;
                margin-right: 6px;
            }
        }
        .dropdown-menu {
            border: none;
            box-shadow: 0px 0px 12px #0000000F;
            margin-top: 55px;
            transform: none !important;
            .dropdown-item {
                font-family: 'Nexa-Heavy';
                font-size: 9px;
                color: #58B5C9;
                font-weight: 400;
                text-transform: uppercase;
                line-height: 20px;
                cursor: pointer;
                &:hover, &:focus, &:active,&:visited,&:focus-visible {
                    background: none ; 
                    border:none ; 
                    outline: none;
                    box-shadow: none ; 
                    color: #58B5C9;
                }
            }
            .dropdown-item.active {
                background: none ; 
                    border:none ; 
                    outline: none;
                    box-shadow: none ; 
                    color: #58B5C9;
            }
        }
    }
    .table_menu {
        border: none;
        .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
            background: none  !important; 
            border:none  !important; 
            outline: none;
            box-shadow: none !important; 
            color: #7B7A7A;
        }
        .dropdown-toggle {
            background: none;
            border:none;
            color: #7B7A7A;
            position: relative;
            font-family: 'Nexa-BoldItalic';
            font-size: 12px;
            outline: none;
            box-shadow: none;
            &::before {
                display: none !important;
            }
            &:hover, &:focus, &:active,&:visited,&:focus-visible {
                background: none  !important; 
                border:none  !important; 
                outline: none;
                box-shadow: none !important; 
                color: #7B7A7A;
            }
            
        }
        .dropdown-menu {
            border: 0;
            border-radius: 4px;           
            padding: 0 !important;
            background: #58b5c9;
            a {
                display: inline-block;
                padding: 18px 12px 14px 12px !important;
                font-size: 14px!important;
                border-radius: 0;
                font-weight: 400;
                line-height: 1.42857143;
                width: 100% ;
                height: 100% ;
                color: #fff ;
                text-align: left ;
                text-decoration: none;
                border-bottom: 0.5px solid #8ccad8 !important;
            }
           
        }
    }
    /*Nav Style Ends*/ 

    /*main container styles*/
    .main-content {
        padding-left: 99px;
        padding-top: 78px;
        padding-right: 50px;
    }

    .inner-title {
        margin-bottom: 25px;
        display: flex;
        h3 {
            font-size: $font-mediumxxx;
            margin: 0px;
            font-family: 'Nexa-BlackItalic';
            text-transform: uppercase;
        }
        .path_navigation {
            margin-left: auto;
            ul {
                list-style: none;
                padding: 0px 0px 0px 10px;
                margin: 0px;
                li {
                    display: inline-block;
                    font-family: 'Nexa-BoldItalic';
                    font-size: $font-basex;
                    text-transform: uppercase;
                    padding-right: 20px;
                    position: relative;
                    &:last-child {
                        padding-right: 0px;
                    }
                    a {
                        color: $blue;
                        text-decoration: none;
                    }
                    a::after {
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 10px;
                        right: 5px;
                        top: 2px;
                        background-image: url('./../images/keyboard-right-arrow-button.svg');
                        background-repeat: no-repeat;;
                    }
                }
            }
        }
    }

    .icon_boxes_list {
        display: flex;
        .icon_box {
            width: 200px;
            height: 146px;
            border: 2px solid $blue;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            margin-right: 32px;
            cursor: pointer;
            text-decoration: none;
            .icon_white {
                display: none;
            }
            p {
                margin: 0px;
                color: $blue;
                font-size: $font-mediumx;
                line-height: 16px;
                padding-top: 10px;
            }
            &:hover {
                background: $blue;
                .icon_color {
                    display: none;
                }
                .icon_white {
                    display: inline;
                }
                p {
                    color: $white;
                }
            }
        }
    }

    .table_data {
        background: $white;
        border-radius: 5px;
        overflow: hidden;
        .table_header {
            padding: 10px;
            display: flex;
            .btn-addnew {
                margin-left: auto;
                background: $blue;
                color: $white;
                border:none;
                border-radius: 3px;
                font-family: 'Nexa-Heavy';
                // font-family: 'Nexa-Bold';
                font-size: $font-mediumx;
                min-width: 130px;
                text-align: center;
                padding: 11px 15px 10px;
                text-decoration: none;
                span {
                    font-family: 'Nexa-Regular';
                    font-size: 26px;
                    line-height: 14px;
                    padding-left: 10px;
                    position: relative;
                    top: 4px;                
                }
            }
            
        }
        .custom_table {
            width: 100%;
            thead {
                box-shadow: 0px 4px 4px #00000015;
                padding: 0px 20px;
                margin-bottom: 10px;
                tr {
                    th {
                        font-family: 'Nexa-Heavy';
                        font-size: 12px;
                        color: #9CAEC1;
                        text-transform: uppercase;
                        padding: 10px 20px;
                        font-weight: 400;
                        a {
                            padding-left: 5px;
                            position: relative;
                            top: -1px;
                        }
                    }
                }
            }
            tbody {
                padding: 0px 20px;
                tr {
                    border: 1px solid #F0F4F7;
                    td {
                        font-family: 'Nexa-Regular';
                        font-size: $font-medium;
                        color: #5D5D5D;
                        padding:20px 30px;
                        
                    }
                }
            }
        }
    }
}


.group_panel {
    border: 1px solid #D9D9D9;
    padding: 25px;
    border-radius: 5px;
    h4 {
        font-family: 'Nexa-Heavy';
        font-size: $font-basexx;
        color: $blue;
    }
    .user_permissin_list {
        display: flex;
        flex-wrap: wrap;
        .permission_item {
            flex: 0 1 33.33%;
            padding: 8px;
            >label  {
                display: inline-block;
                width: 100%;
                min-height: 135px;
                background: #E8E8E8;
                border-radius: 5px;
                padding: 16px;
                position: relative;
                input {
                    position: absolute;
                    right: 8px;
                    top: 8px;
                }
                .RadioButton {
                    position: absolute;
                    right: 2px;
                    top: 2px;
                }
            }
            h5 {
                color: #646464;
                font-size: $font-base;
                font-family: 'Nexa-Bold';
                padding-right: 30px;
            }

            p {
                margin-bottom: 5px;
                background: $blue;
                color: $white;
                border-radius: 5px;
                font-size: $font-basex;
                padding: 8px;
                /*text-transform: capitalize;*/
            }
           
        }
    }
}

.srv-validation-message{
    font-size: 12px;
    color: red;
}

.select-multi {
    div[class$="control"] {
        background-color: #F4F4F4;
        border: none;
    }
    div{
        font-size: 12px;
    }
}

.select-group {
    div[class$="control"] {
        background-color: #F4F4F4;
        border: none;
    }
    div{
        font-size: 13px;
        /*text-transform: capitalize;*/
    }
}

textarea.form-control.yellow-bg {
    background-color: #F3FF96;
}



/* Radio button and checkbox style */
$md-radio-checked-color: #58B5C9;
$md-radio-border-color: rgba(0, 0, 0, 0.54);
$md-radio-size: 15px;
$md-radio-checked-size: 6px; 
$md-radio-ripple-size: 10px;

@keyframes ripple {

  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
  }

  50% { 
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
  }

  100% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
  }
}

.RadioButton {
    margin: 0px 0;

    input[type="radio"] {
        display: none;

        &:checked + label:before {
            border: none;
            background-color: $md-radio-checked-color;
            background-image:url(./../images/checked.svg);
            background-repeat: no-repeat;
            background-position: 3px 2px;
            animation: ripple 0.2s linear forwards;   
        }

        // &:checked + label:after {
        //    // transform: scale(1);
        // }
    }
 
    label {
        display: inline-block;
        height:$md-radio-size;
        position: relative;
        padding: 1px 0px 1px ($md-radio-size + 5px) ;
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;
        font-size: 11px;
        color: #6A6A6A;
        &:before, &:after {
            position: absolute;            
            content: '';  
            border-radius: 50%;
            transition: all .3s ease;
            transition-property: transform, border-color;
        }

        &:before {
            left: 0;
            top: 0;
            width: $md-radio-size;
            height: $md-radio-size;
            background-color: #fff;
            border: 1px solid $md-radio-checked-color;
        }

        &:after {
            top: $md-radio-size / 2 - $md-radio-checked-size / 2;
            left: $md-radio-size / 2 - $md-radio-checked-size / 2;
            width:$md-radio-checked-size;
            height:$md-radio-checked-size;
            transform: scale(0);
            background:$md-radio-checked-color;
        }
    }
}

.checkBoxButton {
    margin: 0px 0;
    background: #F2F2F2;
    margin-bottom: 5px;
    padding-bottom: 8px;
    padding-left: 5px;
    input[type="checkbox"] {
        display: none;

        &:checked + label:before {
            // border-color: $md-radio-checked-color;
            border:none;
            background-color: $md-radio-checked-color;
            background-image:url(./../images/checked.svg);
            background-repeat: no-repeat;
            background-position: 3px 2px;
            animation: ripple 0.2s linear forwards;   
        }

        // &:checked + label:after {
        //    // transform: scale(1);
        // }
    }
    label:before, label:after{
        border-radius: 0 !important;
    }
    label {
        display: inline-block;
        height:$md-radio-size;
        position: relative;
        padding: 1px 0px 1px ($md-radio-size + 5px) ;
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;
        font-size: 12px;
        color: #6A6A6A;
        width: 100%;
        line-height: 13px;
        &:before, &:after {
            position: absolute;            
            content: '';  
            border-radius: 50%;
            transition: all .3s ease;
            transition-property: transform, border-color;
        }

        &:before {
            left: 0;
            top: 0;
            width: $md-radio-size;
            height: $md-radio-size;
            background-color: #fff;
            border: 1px solid $md-radio-checked-color;
        }

        &:after {
            top: $md-radio-size / 2 - $md-radio-checked-size / 2;
            left: $md-radio-size / 2 - $md-radio-checked-size / 2;
            width:$md-radio-checked-size;
            height:$md-radio-checked-size;
            transform: scale(0);
            background:$md-radio-checked-color;
        }
    }
}

.checkBoxButton.checkBoxButton_inline {
    background: none;
    display: inline-block;
    padding-right: 10px;
}


/*Change PIN*/

.change_pin_form {
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    padding: 25px;
    margin-bottom: 30px;
    margin-right: 135px;
    .oldpin {
        padding-left: 30px;
        background-image: url('../images/password.svg');
        background-size: 14px 16px;
        background-repeat: no-repeat;
        background-position: 0px 10px;
        span {
            display: flex;
            font-size: 60px;
            letter-spacing: -3px;
            line-height: 0px;
            position: relative;
            top: -10px;
            margin-bottom: 35px;
        }
    }

    .new_pin {
        position: relative;
        &::after {
            content: 'Enter Pin';
            position: absolute;
            left: 10px;
            top: 12px;
            font-family: 'Nexa-RegularItalic';
            color: #000000;
            font-size: 14px;
            
        }
        input.form-control {
            padding-left: 100px;
            font-size: 18px;
            line-height: 18px;
            padding: 11px 10px 10px 100px;
            text-align: center;
            letter-spacing: 12px;
        }
    }
}

.search-icon .form-control{
    width: 15vw;
  }
  a {
    cursor: pointer;
  }


  /* Modal Desing*/
  .custom_modal  { 
      .modal-header {
            border-bottom: none;
            .h4 {
                font-family: 'Nexa-HeavyItalic';
                font-size: 20px;
            }
      }
      .modal-dialog {
        height: 100%;
        max-width: 100%;
        margin: 0;
      }
      .modal-content {
        border: none;
        border-radius: 0px;
        height: 100%;
        background: #F7FAFC;
        position: fixed;
        top:0px;
        left: 0px;
        overflow: auto;
      }
      .invitationBox {
          width: 290px;
          background: #fff;
          position: relative;
          box-shadow: 0px 0px 15px #00000017;
          margin: 20px auto;
          padding:30px;
          .form-group {
              margin-bottom: 5px;
              label {
                  margin-bottom: 0px;
              }
              p {
                  font-size: 12px;
                  color: #5A5A5A;
                  margin-bottom: 0px;
              }
          }
          .btn-submit {
              margin-right: 5px;
          }
      }
      .delete-msg {
          h4 {
            font-family: 'Nexa-HeavyItalic';
            font-size: 19px;
            margin-bottom: 20px;
          }
      }
      .user-deatils-box {
          display: flex;
          overflow: hidden;
          .user-img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            margin-top: 1px;
            align-items: center;
            display: flex;
            border: 1px solid #ddd;
              img {
                  max-width: 100%;
              }
          }
          .user-deatils {
              h4 {
                font-family: 'Nexa-HeavyItalic';
                font-size: 19px;
                white-space:nowrap;
                overflow:hidden;
                margin-bottom: 0px;
                margin-top: 15px;
                margin-left: 5px;
                max-width: 170px;
                text-overflow: ellipsis;
              }
              p {
                font-size: 12px;
                color: #5A5A5A;
                margin-bottom: 0px;
                white-space:nowrap;
                overflow:hidden;
              }
          }
      }
  }
  
.custom_modal .modal-content{
  background: transparent !important;
}
.modal-content{
    overflow: hidden;
}
.custom_modal_1 {
    .modal-header {
        padding: 10px;
        .h4 {
            font-family: 'Nexa-HeavyItalic';
            font-size: 22px;
        }
        .modalClose {
            right: 10px;
            top: 10px;
            img {
                width: 12px;
            }
        }
        .search-icon {
            right: 80px;
            width: 250px;
            input {
                width: 100%;
            }
        }
    }
    textarea {
        min-height: 100px;
    }
    .reduired {
        color: #C95858;
    }
    .modal-footer {
        position: relative;
        span {
            position: absolute;
            left: 20px;
            color: #848484;
            font-size: 13px;
        }
    }
}
.modalClose{
  position: absolute;
  right: 4px;
  top: 0;
  background: #fff;
  padding: 4px;
}
.dropdown-menu.show{
  margin-top: -63px;
  box-shadow: -6px 0px 10px #ddd;
}
.page_container .table_menu .dropdown-menu a:hover{
  color: #58B5C9;
  background: #fff;
}
.page_container .table_menu  button {
    padding-left: 0px;
    span {
        background-size: 95%;
    }
}
.page_container .table_menu .dropdown-menu a{
  color: #fff;
}

.text-success {
  color: #28a745!important;
  /*text-transform: capitalize;*/
}

.nodata{
  padding: 100px !important;
  text-align: center;
  font-size: 18px !important;
}
.bd-highlight,.nodata{
    font-family: 'Nexa-BlackItalic' !important
}
.accepted{
    color: #28a745!important;
    /*text-transform: capitalize;*/
  }
  .pending,.Pending{
    color: #d97c08!important;
    /*text-transform: capitalize;*/
  }
  .rejected{
    color: red!important;
    /*text-transform: capitalize;*/
  }
  .new{
    color: #b20986!important;
    /*text-transform: capitalize;*/
  }
  .optout{
    color: rebeccapurple!important;
    /*text-transform: capitalize;*/
  }  
/*table tr td.capitalize,.capitalize{
    text-transform: capitalize;
  }*/
a {
  cursor: pointer;
}
.center{
  text-align: center;
}

.center{
  text-align: center;
}
.required{
	color: #dc5454;
    font-size: 14px;
    position: relative;
    top: 2px;
}
header{
    padding-left: 92px !important;
    padding-right: 23px !important;
  }
  button#dropdown-basic {
    padding-right: 0;
}
.dropdown-menu.show svg{
    background: white;
    padding: 6px;
    border-radius: 50%;
    float: left;
    margin-right: 5px;
    width: 25px;
    height: 25px;
    margin-top: -3px;
    color: #58b5c9;
  
  }
.search-icon .form-control{
  width: 15vw;
}
.fixed{
  position: fixed;
  top: 0px;
  width: 100vw;
  height: 10px;
  z-index: 9999999;
}
.Toastify__toast--info {
    background: #5a5e5f !important;
}
// .Toastify__toast-container--top-center{
//     left: 45% !important;
// }
// .Toastify__toast-container{
//     width: 620px !important;
// }
.Toastify__close-button{
    opacity: 1 !important;
}
.Toastify__toast--success{
  
  background: #5a5e5f !important;
}
.Toastify__toast--warning{
  
  background: #5a5e5f !important;
}
.Toastify__progress-bar{
    height: 2px !important;
}
.disabled-link.social{
    background: #f2f2f2 !important;

}
.disabled-link {
    pointer-events: none;
  }
select{ outline: none;}
a,button{
    cursor: pointer;}
button.paginationbtn:disabled{
  pointer-events: none;
  outline: none;
  }
  button.paginationbtn{
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    outline: none;
    cursor: pointer;
  
  }
  a {
    cursor: pointer;
  }
.loading {
  position: fixed;
  width: 137px;
  height: 45px;
  background: #3a3c3d;
  left: 45%;
  top: 0;
  z-index: 10000000000000000;
  color: #fff;
  padding: 12px 12px;
  border-radius: 0px 0px 10px 10px;
  transition: top 350ms ease-in-out;

}
.page_container .table_menu .dropdown-menu a:hover svg{
    background: #58B5C9 !important;
    color: #fff !important;
  }
  /* Festival module starts */
.festival_nav {
    padding-left: 40px;
    a, a:not([href]):not([tabindex]){
        font-size: 12px;
        font-family: 'Nexa-Heavy';
        color: #8D8B8B;
        margin-right: 20px;
        padding: 0px 5px 7px;
        &:hover,&:focus,&:active {
            color: #261722;
            border-bottom: 2px solid #58B5C9;
        }
    }
    a:not([href]):not([tabindex]).active, a.active {
        color: #261722;
        border-bottom: 2px solid #58B5C9;
    }
}
.festival_header {
    margin-bottom: 15px;
    h3 {
        font-size: 24px;
        margin: 15px 0px 0px;
        font-family: 'Nexa-BlackItalic';
    }
  .action_buttons button {
      margin-left: 10px;
  }
}

.festival_module {
    h4 {
        font-family: 'Nexa-HeavyItalic';
        font-size: 22px;
        color: #4D4C4C;
        margin-bottom: 25px;
    }
    .reduired {
        color: #C95858;
    }
    .box {
        background: #fff;
        box-shadow: 0px 0px 6px #00000029;
        padding: 30px;
        margin-bottom: 30px;
        position: relative;
        .right_align {
            position: absolute;
            right: 30px;
            top:10px;
        }
    }
}

.amenites-list {
    display: flex;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
    .amenites-item {
        flex: 50% 1;
        padding: 10px;
        box-sizing: border-box;
        max-width: 50%;
    }
    .amenites-item-inner {
        background: #F5F5F5;
        border-radius: 6px;
        padding: 30px;
        position: relative;
        height: 100%;
        .delete-icon {
            width: 14px;
            height: 14px;
            display: inline-block;
            background-image: url(../images/delete.svg);
            background-repeat: no-repeat;
            position: absolute;
            right: 10px;
            top: 10px;
        }
        h5 {
            font-size: 14px;
            font-family: 'Nexa-Regular';
            color: #4D4C4C;
        }
        p {
            font-size: 12px;
            font-family: 'Nexa-Regular';
            color: #4D4C4C;
        }
    }
}


.vendor-list {
    display: flex;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    .vendor-item {
        padding: 10px;
        box-sizing: border-box;
        max-width: 100;
    }
    .vendor-item-inner {
        background: #F5F5F5;
        border-radius: 6px;
        padding: 30px;
        position: relative;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        img {
            max-width: 155px;
            max-height: 155px;
            height: 155px;
            width: 155px;
            border-radius: 50%;
        }
        .vendor_content {
            text-align: left;
            padding-left: 15px;
        }
        .delete-icon {
            width: 14px;
            height: 14px;
            display: inline-block;
            background-image: url(../images/delete.svg);
            background-repeat: no-repeat;
            position: absolute;
            right: 10px;
            top: 10px;
        }
        span {
            font-size: 13px;
            font-family: 'Nexa-Regular';
            color: #6B6B6B;
        }
        p {
            font-size: 12px;
            font-family: 'Nexa-Regular';
            color: #171717;
        }
    }
}


.schedule-list {
    table {
        tr {
            background: #F0F4F7;
            border: 1px solid #F0F4F7;
            margin-bottom: 10px;
            td{
                &:first-child {
                    p {
                        color: #000000;
                        font-size: 13px;
                        font-family: 'Nexa-Regular';
                        margin-bottom: 0px;
                    }
                    span {
                        font-size: 13px;
                        color: #929292;
                    }
                }
                span.time {
                    font-size: 14px;
                    color: #929292;
                }
            }
            
        }
    }
}
.disabled{
    pointer-events: none;
}
.dsp{
    a,p {
        font-size: 10px !important;
        color: #929292;
    }
    a{
        color: #58B5C9 !important;
        display: block;
    }
}

.amenites_check_list {
    height: 200px;
    overflow-y: auto;
    .checkBoxButton  {
        background: none;
        border-bottom: 1px solid #f2f2f2;
        label {
            color: #000000;
            img {
                width: 34px;
                height: 34px;
                border-radius: 50%;
            }
        }
    }
}

.vendors_check_list {
    height: 200px;
    overflow-y: auto;
    .checkBoxButton  {
        background: none;
        border-bottom: 1px solid #f2f2f2;
        label {
            height: 30px;
            color: #000000;
            &:before {
                top:8px
            }
            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-right: 10px;
                margin-left: 10px;
                border: 1px solid grey;
            }
        }
    }
}
.banner_upload {
    position: relative;
    overflow: hidden;
    display: block;
    .form-control {
        position: absolute;
        top:-200px
    }
}
.banner_img img {
    width: 100%;
    margin: 0 auto;
  }
.banner_img {
    background: #E5E5E5;
    border-radius: 4px;
    height: 263px;
    width: 465px;
    display: flex;
    text-align: center;
    max-width: 100%;
    align-items: center;
    overflow: hidden;
    .no_banner_img {
        display: flex;
        text-align: center;
        align-items: center;
        max-width: 100%;
        width: 128px;
        flex-direction: column;
        height: 128px;
        background: #fff;
        border-radius: 50%;
        margin: 0 auto;
        padding-top: 32px;
        img{
            height: 50px;
        }
        span {
            color: #9F9F9F;
            padding-top: 5px;
            font-family: 'Nexa-Regular';
        }
    }
    .onedit.no_banner_img{
        left: 50%;
        margin-left: 20px;
    }
    img {
        max-width: 100%;
        margin: 0 auto;
    }
}

.profile_upoad {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    .profieimg {
        width: 80px;
        height: 80px;
        overflow: hidden;
        border-radius: 50%;
        text-align: center;
        background: #DEDEDE;
        box-shadow: 0px 3px 6px #00000029;
        border: 3px solid #FFFFFF;
        min-width: 80px;
        display: flex;
         align-items: center;
        .no_pofile_img {
            width: 100%;
            padding: 20px;
        }
        img {
            width: 100%;            
        }
    }
    label {
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items:center;
        font-size: 12px;
        .form-control {
            position: absolute;
            top:-200px
        }
        .attach {
            background: #58B5C9;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            min-width: 30px;
            overflow: hidden;
            text-align: center;
            font-size: 12px;
            margin:0px 10px;
            img {
                max-width: 100%;
                position: relative;
                top: 5px;
                
            }
        }
    }
}
.label_block {
    label {
        display: block;
    }
 
}
    .react-datepicker-wrapper {
        width: 100%;
    }
    .Showcase__Content__wrapper {
        display: table;
    }
.Showcase__style__list{
    width: 400px;
    height: 600px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #999;
}
.Showcase__Content__iframe, .Showcase__Content__root {
    position: relative;
    z-index: 0;
    padding: 10px 0;
    border: 0;
    box-sizing: border-box;
}
.Showcase__style__stylizedList{
    position: relative;
    z-index: 0;
    background-color: #f3f3f3;
    border: 1px solid #efefef;
    border-radius: 3px;
    outline: none;
} .Showcase__style__grid{
    display: block;
    width: 100%;
    height: auto;
    white-space: nowrap;
    border: 0;
    background-color: transparent;
}
.Showcase__style__stylizedItem {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    background-color: #fff;
    border-bottom: 1px solid #efefef;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #333;
    font-weight: 400;
    background-size: cover !important;
    width: 90px;
    height: 65px;
    display: grid;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 25px;
    position: relative;
    margin-bottom: 15px;
    overflow: hidden;
    &:first-child::after {
        content: 'Main Image';
        position: absolute;
        bottom: -17px;
        width: 100%;
        text-align: center;
        color: #58B5C9;
        font-size: 12px;
    }
    img {
        max-width: 100%;
    }
    .delete_img {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: #fff;
        background-image: url(../images/delete.svg);
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        background-size: 11px 19px;
        left: auto;
        top: 10px;
        right: 10px;
        margin: 0 auto;
    }
}
.Showcase__style__gridItem:hover {
    border: 2px dashed #58B5C9;
}
.Showcase__style__gridItem {
    float: left;
    width: 125px;
    padding: 8px;
    background: transparent;
    border: 0;
    margin: 5px;
    height: 95px;
    border-radius: 8px;
    border: 2px solid transparent;
    .Showcase__style__wrapper {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: #fff;
        border: 1px solid #efefef;
        font-size: 28px;
    }
}
.banner_images_list {
    width: 100%;
    padding: 10px 0px;
    display: flex;
    .banner_item {
        background-size: cover !important;
        width: 90px;
        height: 65px;
        display: grid;
        align-items: center;
        text-align: center;
        border-radius: 4px;
        border: 1px solid #ccc;
        margin-right: 25px;
        position: relative;
        margin-bottom: 15px;
        overflow: hidden;
        &:first-child::after {
            content: 'Main Image';
            position: absolute;
            bottom: -17px;
            width: 100%;
            text-align: center;
            color: #58B5C9;
            font-size: 12px;
        }
        img {
            max-width: 100%;
        }
        .delete_img {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background-color: #fff;
            background-image: url(../images/delete.svg);
            background-size: 18px 19px;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            left: 0px;
            right: 0px;
            margin: 0 auto;
        }
    }
}
.banner_upload_edit {
    max-width: 125px;
    height: 103px;
    .banner_img {
        height: 103px;
    }
    .no_banner_img {
        width: 85px;
        height: 85px;
        margin-left: -43px;
        padding-top: 17px;
        img {
            height: 28px;
        }
    }
}
.banner_images_list_edit {
    .banner_item .delete_img {
        width: 22px;
        height: 22px;
        background-size: 11px 19px;
        left: auto;
        right: 5px;
        top: 5px;
    
    }
}
.e-control.e-datepicker.e-input,.e-control.e-timepicker.e-input{
pointer-events: none;
}
.e-clear-icon{
    display: none !important;
}
.mobile_preview {
    width: 100%;
    text-align: center;
    padding-top:15px;
    .preview_title {
        font-size: 14px;
        color: #58B5C9;
        font-family: 'Nexa-Heavy';
        text-transform: uppercase;
    }
    .mobile_preview_inner {
        width: 440px;
        height: 869px;
        background-image: url(../images/mobile.png);
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        padding: 96px 34px 105px 34px;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    .mobile_preview_container {
        width: 100%;
        height: 90%;
        overflow-y: auto;
    }
    .mobile_banner {
        height:200px;
        background-color: #E5E5E5;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;
        display: flex;
        text-align: center;
        align-items: center;
        overflow: hidden;
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.40);
            z-index: 9;
            top:0;
            left: 0;
        }
        img {
            max-width: 100%;
            width: 100%;
        }
        .mobile_banner_title {
            z-index: 99;
            position: absolute;
            bottom: 10px;
            text-align: left;
            padding: 10px;
            span {
                background: #FF9B64;
                background: linear-gradient(180deg, #FF9B64 0%, #FF8585 100%) 0% 0% no-repeat padding-box;
                color: #fff;
                display: inline-block;
                padding: 2px 5px;
                font-family: 'Nexa-HeavyItalic';
                font-size: 12px;
                margin-bottom: 5px;
            }
            h2 {
                font-size: 24px;
                font-family: 'Nexa-HeavyItalic';
                color: #fff;
                letter-spacing: 0.5px;
                margin-bottom: 0px;
            }
        }
    }
    .mobile_amenites {
        .amenites-item {
            padding:10px;
            border-bottom: 1px solid #f2f2f2;
            display: flex;
            align-items: center;
        }
        .amenites-item-left {
            min-width: 50px;
        }
        .amenites-item-right {
            h5 {
                font-size: 16px;
                margin-bottom: 0px;
                font-family: 'Nexa-Bold';
                font-weight: 300;
            }
            p {
                font-size: 9px;
                margin-bottom: 0px;
                color: #4D4C4C;
            }
        }
    }
  
    .mobile_tabs {
        .nav-tabs {
            border: none;
            -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.22);
            -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.22);
            box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.22);
            .nav-link {
                border: none;
                width: 33.3%;
                font-family: 'Nexa-Heavy';
                font-size: 14px;
                color: #848484;
            }
            .nav-item {
                margin-bottom: 0px;
                
            }
            .nav-item.active {
                border-bottom: 2px solid #58B5C9;
                color: #000;
            }
        }
        .tab-content {
            padding: 10px;
            text-align: left;
            .blue_label {
                font-size: $font-small;
                color: $blue;
                font-family: 'Nexa-Heavy';
                text-transform: uppercase;
                margin-bottom: 0px;
            }
            h4 {
                font-size: 20px;
                font-family: 'Nexa-HeavyItalic';
                letter-spacing: 0.5px;
                margin-bottom: 0px;
                padding-right: 50px;
                background-image: url("./../images/arrow_icon.png");
                background-position: right;
                background-size: 25px 25px;
                background-repeat: no-repeat;
            }
            .mobile_overview_info {
                padding-top: 15px;
                display: flex;
                .mobile_overview_item {
                    padding-right: 25px;
                }
                p {
                    font-family: 'Nexa-Regular';
                    font-size: 14px;
                    color: #000;
                    // line-height: 14px;
                }
            }
            .buttonlink{
                color: inherit;
                text-decoration: blink;
            }
                            
              

                
            .truncate-overflow2 {
                --max-lines: 2;
                position: relative;
                max-height: calc(var(--lh) * var(--max-lines));
                overflow: hidden;
                padding-right: 1rem; /* space for ellipsis */
                font-size: 14px;
            }
            .truncate-overflow2::before {
                position: absolute;
                content: "...";
                /* tempting... but shows when lines == content */
                /* top: calc(var(--lh) * (var(--max-lines) - 1)); */
                
                /*
                inset-block-end: 0;
                inset-inline-end: 0;
                */
                bottom: 0;
                right: 0;
            }
            .truncate-overflow2::after {
                content: "";
                position: absolute;
                /*
                inset-inline-end: 0;
                */
                right: 0;
                /* missing bottom on purpose*/
                width: 1rem;
                height: 1rem;
                background: white;
            }

                
                .truncate-overflow {
                    --max-lines: 3;
                    position: relative;
                    max-height: calc(var(--lh) * var(--max-lines));
                    overflow: hidden;
                    padding-right: 1rem; /* space for ellipsis */
                }
                .truncate-overflow::before {
                    position: absolute;
                    content: "...";
                    /* tempting... but shows when lines == content */
                    /* top: calc(var(--lh) * (var(--max-lines) - 1)); */
                    
                    /*
                    inset-block-end: 0;
                    inset-inline-end: 0;
                    */
                    bottom: 0;
                    right: 0;
                }
                .truncate-overflow::after {
                    content: "";
                    position: absolute;
                    /*
                    inset-inline-end: 0;
                    */
                    right: 0;
                    /* missing bottom on purpose*/
                    width: 1rem;
                    height: 1rem;
                    background: white;
                }
        }
    }
}
.hint{
    float: right;
    font-size: 12px;
    font-style: italic;
}

.reviews-list {
    padding: 28px 56px;
    h4 {
        font-size: 13px;
        font-family: 'Nexa-HeavyItalic';
        color: #2F2F2F;
    }
    .reviews-list-inner {
        background: #F5F5F5;
        padding: 45px 50px;
        margin-bottom: 20px;
        h3 {
            font-family: 'Nexa-HeavyItalic';
            color: #2F2F2F;
            font-size: 20px;
            padding-bottom: 15px;
            span {
                float: right;
                font-size: 12px;
                color: #68727C;
                text-transform: uppercase;
                font-family: 'Nexa-Heavy';
            }
        }
    }
    .review_item {
        padding: 15px 50px 15px 0px;
        position: relative;
        border-bottom: 1px solid #D8D8D8;
        .review-user {
            color: #58B5C9;
            font-family: 'Nexa-Heavy';
            font-size: 9px;
            margin-bottom: 0px;
            span {
                font-size: 10px;
                color: #6C7374;
                padding-left: 15px;
            }
        }
        .review-des {
            font-size: 13px;
            color: #4D4C4C;
            margin-bottom: 0px;
            margin-top: -10px;
        }
        .review_delete {
            position: absolute;
            right: 20px;
            top: 50%;
            margin-top:-12px;
        }
        .dv-star-rating {
            label {
                margin-bottom: 0px;
            }
        }
    }
}

.userFileUpload {
    display: block;
    position: relative;
    overflow: hidden; 
    background: #F4F4F4;
    border: none;
    padding: 11px 12px;
    font-size: 14px;
    color: rgba(77, 76, 76, 0.52);
    font-family: 'Nexa-BoldItalic';
    input {
        position: absolute;
        top: -100px;
    }
    span {
        width: 104px;
        height: 100%;
        text-align: center;
        border-left: 1px solid #e6e6e6;
        color: #58b5c9;
        font-family: 'Nexa-Heavy';
        font-size: 10px;
        right: 0px;
        position: absolute;
        padding-top: 4px;
        text-transform: uppercase;
    }
}

.profileUploadedImg {
    display: flex;
    align-items: center;
    margin-top: 20px;
    position: relative;
    .profileUploadedImg_inner {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        text-align: center;
        background: #DEDEDE;
        position: relative;
        overflow: hidden;
    }
    img {
        width: 100%;
        //max-height: 100%;
        margin: 0 auto;
    }
    .delete-icon {
        width: 23px;
        height: 23px;
        display: inline-block;
        background-image: url(../images/delete.svg);
        background-repeat: no-repeat;
        border-radius: 50%;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        background-position: center;
        margin-left: 5px;
    }
}

.tempInfo {
    width: 12px;
    height: 12px;
    border: 1px solid #58B5C9;
    background-image: url(../images/info.svg);
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    position: relative;
    float: right;
    top: 7px;
    &:hover {
        .cs_tooltip {
            display: block;
        }
    }
    .cs_tooltip {
        display: none;
        width: 209px;
        padding: 16px 25px;
        background: #fff;
        border-radius: 3px;
        box-shadow: 0px 0px 10px #0000001A;
        position: absolute;
        z-index: 99;
        top: -46px;
        right: -56px;
        h4 {
            font-family: 'Nexa-Bold';
            color: #58B5C9;
            font-size: 10px;
        }
        p {
            font-family: 'Nexa-Regular';
            color: #505050;
            font-size: 9px;
            line-height: 12px;
            margin-bottom: 0px;
        }
    }
}
.Toastify__toast{
    border-radius: 3px;
    min-height: 30px !important;
}
.Toastify__toast--warning{
    .Toastify__toast-body{
        padding-left: 25px;
        background-image: url(../images/warning.svg);
        background-repeat: no-repeat;
        background-position: left;
        background-size: contain;
        font-size: 12px;
    }
}
.Toastify__toast--info{
    .Toastify__toast-body{
        padding-left: 25px;
        background-image: url(../images/loading.svg);
        background-repeat: no-repeat;
        background-position: left;
        background-size: contain;
        font-size: 12px;
    }
}
.Toastify__toast--success{
    .Toastify__toast-body{
        padding-left: 25px;
        background-image: url(../images/like1.svg);
        background-repeat: no-repeat;
        background-position: left;
        font-size: 12px;
    }
}
.Toastify__toast--error{
    .Toastify__toast-body{
        padding-left: 25px;
        background-image: url(../images/dislike.svg);
        background-repeat: no-repeat;
        background-position: left;
        font-size: 12px;
    }
}
.e-input-group:not(.e-disabled):not(.e-float-icon-left)::before {
    display: none;
}
.e-input-group:not(.e-disabled):not(.e-float-icon-left)::after {
    display: none;
}

.input_data {
    font-size: 14px;
    color: #4D4C4C;
    margin-top: -10px;
    /*text-transform: capitalize;*/
}
.action_buttons  {
    button{
        margin-left: 10px;
    }
    span {
        text-transform: uppercase;
        color: #AFAFAF;
        font-family: 'Nexa-Heavy';
        padding-right: 10px;
    }
}

.invitation_history {
    margin-top: 60px;
    padding: 40px;
    background: #F7F7F7;
    h3 {
        color: #58B5C9;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Nexa-Heavy';
        text-transform: uppercase;
        margin-bottom: 21px;
    }
    .invitation_history_inner {
        display: flex;
        .history_item {
            min-width: 300px;
            label {
                color: #818181;
                font-size: 14px;
                font-family: 'Nexa-BookItalic';
                margin-bottom: 7px;
            }
            p {
                font-size: 14px;
                font-family: 'Nexa-Regular';
                color:#2C2C2C;
                margin-bottom: 0px;
            }
            p.history_status {
                color: #414141;
                font-size: 11px;
                font-family: 'Nexa-RegularItalic';
            }
        }
    }
}

.emailSubscribe {
    margin-top: 35px;
    background: #F7F7F7;
    display: flex;
    align-items: center;
    padding: 0px 22px;
    p {
        color: #58B5C9;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Nexa-Heavy';
        text-transform: uppercase;
        margin-bottom: 21px;
        margin: 0px;
        flex: 1;
    }
    .count {
        width: 40px;
        height: 40px;
        border: 1px solid #D5D5D5;
        text-align: center;
        line-height: 40px;
        font-family: 'Nexa-Heavy';
        margin:18px 0px;
        border-radius: 50%;
        margin-left: auto;
        color: #414141;
        
    }
    .arrow {
        width: 40px;
        height: 40px;
        background: #58B5C9;
        text-align: center;
        line-height: 40px;
        border-radius: 50%;
        margin-left: auto;
        margin-left: 20px;
    }
}

.vendor_mobile_preview {
    .profile_pic {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        text-align: center;
        border: 2px solid #fff;
        background-color: #E5E5E5;
        align-items: center;
        display: flex;
        img{
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
        }
    }
    .mobile_banner {
        .mobile_banner_title{
            h2 {
                font-weight: 300;
            }
        } 
    } 
    .rating {
        display: flex;
        p {
            color: #fff;
            font-size: 12px;
            line-height: 24px;
            padding-left: 5px;
            margin-bottom: 0px;
        }
    }
    .vendor_features {
        border-top:1px solid #e2e2e2;
        border-bottom: 1px solid #e2e2e2;
        display: flex;
        flex-wrap: wrap;
        padding: 15px 10px;
        margin-bottom: 10px;
        .feature_img {
            min-width: 25%;
            text-align: center;
        }
    }
    .form-group{
        p{
            font-size: 12px;
            color: #4D4C4C;
        }
    }
}


.festival_edit {
    .Showcase__style__gridItem {
        position: relative;
        &:hover {
            border: 2px dashed #58B5C9;
        }
        .delete_img {
            width: 22px;
            height: 22px;
            background-size: 11px 19px;
            left: auto;
            top: 10px;
            right: 10px;
            
        }
    }
}
.overview_footer {
    display: flex;
    align-items: center;
    a {
        margin-left: auto;
    }
    img {
        width:30px;
        margin-left: 10px;
    }
   
}
.overview_footer.social{
        
    padding-right: 30px;
}
    
.mobile_vendors_check_list {
    .vendor_list_item {
        display: flex;
        align-items: center;
        padding:10px;
        border-bottom: 1px solid #f2f2f2;
    }
    .vendor_img {
        max-width: 60px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 1px solid #ccc;
        overflow: hidden;
        text-align: center;
        margin-right: 10px;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .rating {
        display: flex;
        align-items: center;
        margin: -8px 0px;
        p {
            font-size: 12px;
            line-height: 24px;
            padding-left: 5px;
            margin-bottom: 0px;
        }
    }
    .vendor_details {
        h3 {
            font-size: 16px;
        }
        p {
            font-size: 12px;
            margin-bottom: 0px;
        }
    }
}

.mobile_schedule {
    width: 100%;
    padding:10px;
    tr {
        border-bottom: 1px solid #f2f2f2;
        td {
            padding: 5px;
        }
        td:first-child {
            font-size: 10px;
            color: #58B5C9;
            font-family: 'Nexa-Heavy';
            text-transform: uppercase;
            margin-bottom: 5px;
            max-width: 30px;
        }
        td:nth-child(2) {
            p {
                font-size: 16px;
                line-height: 14px;
                margin-bottom: 0px;
            }
            span {
                font-size: 12px;
                line-height: 10px;
                color: #4D4C4C;
            }
        }
    }
}

.border_bottom_preview{
    border-bottom:1px solid #f6f6f6;
    padding-bottom: 10px;
}

.padding_10{
    padding:10px
}
#mobile_tabs-tabpane-festivals,#mobile_tabs-tabpane-ratings{
    img{
        max-width: 100%;
        margin-top: 5px;
    }
}
.rightProfilePos{
    position: absolute;
    right: 11vh;
    top: 13vh;
}
.festval .dropdown-menu.show{
    margin-top: -36px !important;
}
.mobile_preview_inner{
    position: relative;
    .topicons{
        position: absolute;
        z-index: 9999999999999;
        left: 39px;
        top: 108px;
    }
}

.amenites-item{
    position: relative;
    img{
        width: 25px;
    }
    .amenites-item-right{
        img{
            width: 14px;
            padding-left: 6px;
            float: right;
            text-align: right;
            position: absolute;
            right: 0;
            top: 20px;
        }
    }
}
#mobile_tabs-tabpane-schedule{
    img{
        max-width: 100%;
    }
}
.nospace{
    padding: 0 !important;
    margin: 0 !important;
}
#mc_embed_signup {
    clear: left;
    font: 14px Helvetica,Arial,sans-serif;
    position: absolute;
    width: 84.5% !important;
    background: #f2f2f2 !important;
    padding: 1px 16px 26px 16px;
    left: 34px;
    label{
        text-align: left;
        color: #58b5c9;
        padding-bottom: 0 !important;
    }
    input.email{
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da !important;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        background: #fff;
        border: none;
        padding: 11px 12px;
        font-size: 12px;
        border: 1px solid #ABB0B2;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        color: #343434;
        background-color: #fff;
        box-sizing: border-box;
        height: 32px;
        padding: 0px 0.4em;
        display: table-header-group;
        margin: 0;
        width: 237px !important;
        float: left !important;
    }
    .clear {
        display: unset !important;
        float: right !important;
        .button{
            background-color: #58b5c9 !important;
        }
    }

}
.dropdown-item.active, .dropdown-item:active{
    background: #58B5C9 !important;
    color: #fff !important;
}
a.zero.dropdown-item {
    padding: 0;
    margin: 0;
}
.smallhint{
    font-size: 8px;
}
.festival_overview{
    .disabled-link{
        opacity: .5;
    }
}
.modal{
    z-index: 99999999999999999999;
}
.zeropm{
    padding: 0 !important;
    margin: 0 !important;
}
a.active.btn-bordered.right_align {
    background: #58B5C9;
    color: #ffffff;
}
.glabel{
    color: #939393;
    font-size: 10px;
}
.schedule-list.page_container{
        margin-left: 5px;
    table{
        margin-top: 10px;
        margin-left: 8px;
    }
}
span.datelabval.small {
    font-size: 10px !important;
    font-family: Nexa-Regular !important;
}
.mt10{
    margin-top: 10px;
    max-width: 100%;
}
.scheduleModalform{
    margin: 45px 0;
}
h6.error{
    padding: 100px;
    color: red;
}
.hidden{
    display:none;
}
.schedule_pop{
   
    .modal-dialog {
        margin: 6rem auto;
    }
    .modal-dialog{
        max-width:670px;
        width:670px;
    }
   .modal-title{
    padding-left: 6px;
   }
    .modal-header{
        margin: 0;
        height: 40px;
        border-bottom:0;
        h4{
            font-weight: 800;
        }
    }
    .modal-footer{
        margin: 0 15px;
    }
    .modal-body{
        padding-bottom: 0 !important;  
        .EventsScheduleleft{
            padding-top: 20px;
            .btn-bordered{
                display: block;
            }
        }
        .dates{
            .lable{
                font-family: Nexa-BoldItalic;
                font-size: 10px;
                color: #A2A2A2;
                display: block;
            }
            .datelabval{
                font-family: Nexa-BoldItalic;
                font-size: 10px;
                font-weight: 700;
            }
        }     
        .FestivalScheduleFooter{
            border-top: 1px solid #dee2e6;
            .lable{
                font-family: Nexa-BoldItalic;
                font-size: 13px;
                letter-spacing: 0.28px;
                color: #A2A2A2;
            }
           
            .footerInnerlabBox{
                padding: 15px 0;    
            }
        }
        .checkboxlabel{
            font-weight: 800;
            color: #000;
        }
        .datelabval{
            font-family: Nexa-BoldItalic;
            font-size: 13px;
        }
        .EventsScheduleleft{
            box-shadow: 0px 0px 6px #00000029;

        }
    }
    .festival_nav{
        padding:0;
        a{
            font-size: 12px;
            padding: 0px 0px 5px !important;
            text-transform: uppercase;
        }
      
    }
    .checkBoxButton{
        padding-left: 0;
        margin-bottom: 20px;
    }
    .nav-tabs {
        border-bottom: 1px solid #ddd;
        margin-bottom: 20px;
      
      
        .nav-link {
            border: none;
            font-family: 'Nexa-Heavy';
            font-size: 12px;
             color: #848484;
            
        }
        .nav-item {
            margin-bottom: 0px;
            margin-right: 30px;            
        }
        .nav-item.active {
            border-bottom: 2px solid #58B5C9;
            color: #000;
        }
    }
}
div#time-tab-schedule-tabpane-EventsSchedule {
    margin-top: -20px;
}
.pull-right{
      text-align: right;          
}
.rightmzero{
margin-left: 0;
}
a.spinv {
    background: #FF8683 0% 0% no-repeat padding-box;
    svg{
        color: #FF8683 !important;
    }
}
a.eyeoverlap {
    position: absolute;
    right: 13px;
    top: 8px;
}
.password_control_view{
    font-size: 20px !important;
    letter-spacing: 18px !important;
}
.btn.accept{
    background: #f2f2f2;
    text-align: center;
    margin: 2px;
    color: #58B5C9;
    font-weight: 700;
}
.btn.reject{
    background: #f2f2f2;
    margin: 2px;
    color: #CE7E4D;
    font-weight: 700;
}

.btn.optout1{
    background: #f2f2f2;
    margin: 2px;
    color: red !important;
    font-weight: 700;
}
.preemailform{
    p{
        font-size: 12px !important;
    }
}