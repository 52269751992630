@-webkit-keyframes ripple {
  0% {
    -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
            box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
  50% {
    -webkit-box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  100% {
    -webkit-box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0);
            box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0);
  }
}

@keyframes ripple {
  0% {
    -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
            box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
  50% {
    -webkit-box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  100% {
    -webkit-box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0);
            box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0);
  }
}

.checkBoxButton {
  margin: 0px 0;
  background: #F2F2F2;
  margin-bottom: 5px;
  padding-bottom: 8px;
  padding-left: 5px;
}

.checkBoxButton input[type="checkbox"] {
  display: none;
}

.checkBoxButton input[type="checkbox"]:checked + label:before {
  border: none;
  background-color: #58B5C9;
  background-image: url(../../assets/images/checked.svg);
  background-repeat: no-repeat;
  background-position: 3px 2px;
  -webkit-animation: ripple 0.2s linear forwards;
          animation: ripple 0.2s linear forwards;
}

.checkBoxButton label {
  display: inline-block;
  height: 15px;
  position: relative;
  padding: 1px 0px 1px 20px;
  margin-bottom: 0;
  cursor: pointer;
  vertical-align: bottom;
  font-size: 12px;
  color: #6A6A6A;
  width: 100%;
  line-height: 13px;
}

.checkBoxButton label:before, .checkBoxButton label:after {
  position: absolute;
  content: '';
  border-radius: 3px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  -webkit-transition-property: border-color, -webkit-transform;
  transition-property: border-color, -webkit-transform;
  transition-property: transform, border-color;
  transition-property: transform, border-color, -webkit-transform;
}

.checkBoxButton label:before {
  left: 0;
  top: 0;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: 1px solid #58B5C9;
}

.checkBoxButton label:after {
  top: 4.5px;
  left: 4.5px;
  width: 6px;
  height: 6px;
  -webkit-transform: scale(0);
          transform: scale(0);
  background: #58B5C9;
}

.checkBoxButton.checkBoxButton_inline {
  background: none;
  display: inline-block;
  padding-right: 10px;
}
/*# sourceMappingURL=checkbox.css.map */