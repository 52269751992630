$font-family: 'Poppins', sans-serif;


$white: #FFFFFF;
$light-gray: #F4F4F4;
$dark-gray: #848484;
$xdark-gray: #6A6A6A;
$xxdark-gray: #261722;
$xxdark-gray: #1C1C1C;
$black: #000000;
$orange: #FF9B64;
$orange-dark: rgba(206,96,48,0.5);
$pink: #FF8585;
$red: #D93D3D;
$red-dark: #E04646;
$red-light: #FCD5D5;
$blue: #58B5C9;


$font-small: 9px;
$font-base: 10px;
$font-basex: 11px;
$font-basexx: 12px;
$font-medium: 13px;
$font-mediumx: 14px;
$font-mediumxx: 15px;
$font-mediumxxx: 18px;
$font-mediumxxxx: 20px;
$font-large: 24px;
$font-jumbo: 40px;
