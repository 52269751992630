/**
 * @license
 * MyFonts Webfont Build ID 3751288, 2019-04-24T13:52:49-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Nexa-BlackItalic by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/black-italic/
 * Copyright: Modern and elegant sans serif font.
 * 
 * Webfont: Nexa-Black by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/black/
 * Copyright: Modern and elegant sans serif font.
 * 
 * Webfont: Nexa-BoldItalic by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/bold-italic/
 * Copyright: Modern and elegant sans serif font.
 * 
 * Webfont: Nexa-Bold by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/bold/
 * Copyright: Copyright \(c\) 2012 by Svet Simov. All rights reserved.
 * 
 * Webfont: Nexa-BookItalic by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/book-italic/
 * Copyright: Modern and elegant sans serif font.
 * 
 * Webfont: Nexa-Book by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/book/
 * Copyright: Modern and elegant sans serif font.
 * 
 * Webfont: Nexa-HeavyItalic by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/heavy-italic/
 * Copyright: Modern and elegant sans serif font.
 * 
 * Webfont: Nexa-Heavy by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/heavy/
 * Copyright: Modern and elegant sans serif font.
 * 
 * Webfont: Nexa-LightItalic by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/light-italic/
 * Copyright: Modern and elegant sans serif font.
 * 
 * Webfont: Nexa-Light by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/light/
 * Copyright: Modern and elegant sans serif font.
 * 
 * Webfont: Nexa-RegularItalic by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/italic/
 * Copyright: Copyright \(c\) 2012 by Svet Simov. All rights reserved.
 * 
 * Webfont: Nexa-Regular by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/regular/
 * Copyright: Copyright \(c\) 2012 by Svet Simov. All rights reserved.
 * 
 * Webfont: Nexa-ThinItalic by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/thin-italic/
 * Copyright: Copyright \(c\) 2012 by Fontfabric LLC. All rights reserved.
 * 
 * Webfont: Nexa-Thin by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/thin/
 * Copyright: Copyright \(c\) 2012 by Fontfabric LLC. All rights reserved.
 * 
 * Webfont: Nexa-XBoldItalic by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/xbold-italic/
 * Copyright: Modern and elegant sans serif font.
 * 
 * Webfont: Nexa-XBold by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/xbold/
 * Copyright: Modern and elegant sans serif font.
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3751288
 * Licensed pageviews: 20,000
 * 
 * © 2019 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/393d78");

  
@font-face {font-family: 'Nexa-BlackItalic';src: url('webfonts/393D78_0_0.eot');src: url('webfonts/393D78_0_0.eot?#iefix') format('embedded-opentype'),url('webfonts/393D78_0_0.woff2') format('woff2'),url('webfonts/393D78_0_0.woff') format('woff'),url('webfonts/393D78_0_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Nexa-Black';src: url('webfonts/393D78_1_0.eot');src: url('webfonts/393D78_1_0.eot?#iefix') format('embedded-opentype'),url('webfonts/393D78_1_0.woff2') format('woff2'),url('webfonts/393D78_1_0.woff') format('woff'),url('webfonts/393D78_1_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Nexa-BoldItalic';src: url('webfonts/393D78_2_0.eot');src: url('webfonts/393D78_2_0.eot?#iefix') format('embedded-opentype'),url('webfonts/393D78_2_0.woff2') format('woff2'),url('webfonts/393D78_2_0.woff') format('woff'),url('webfonts/393D78_2_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Nexa-Bold';src: url('webfonts/393D78_3_0.eot');src: url('webfonts/393D78_3_0.eot?#iefix') format('embedded-opentype'),url('webfonts/393D78_3_0.woff2') format('woff2'),url('webfonts/393D78_3_0.woff') format('woff'),url('webfonts/393D78_3_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Nexa-BookItalic';src: url('webfonts/393D78_4_0.eot');src: url('webfonts/393D78_4_0.eot?#iefix') format('embedded-opentype'),url('webfonts/393D78_4_0.woff2') format('woff2'),url('webfonts/393D78_4_0.woff') format('woff'),url('webfonts/393D78_4_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Nexa-Book';src: url('webfonts/393D78_5_0.eot');src: url('webfonts/393D78_5_0.eot?#iefix') format('embedded-opentype'),url('webfonts/393D78_5_0.woff2') format('woff2'),url('webfonts/393D78_5_0.woff') format('woff'),url('webfonts/393D78_5_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Nexa-HeavyItalic';src: url('webfonts/393D78_6_0.eot');src: url('webfonts/393D78_6_0.eot?#iefix') format('embedded-opentype'),url('webfonts/393D78_6_0.woff2') format('woff2'),url('webfonts/393D78_6_0.woff') format('woff'),url('webfonts/393D78_6_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Nexa-Heavy';src: url('webfonts/393D78_7_0.eot');src: url('webfonts/393D78_7_0.eot?#iefix') format('embedded-opentype'),url('webfonts/393D78_7_0.woff2') format('woff2'),url('webfonts/393D78_7_0.woff') format('woff'),url('webfonts/393D78_7_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Nexa-LightItalic';src: url('webfonts/393D78_8_0.eot');src: url('webfonts/393D78_8_0.eot?#iefix') format('embedded-opentype'),url('webfonts/393D78_8_0.woff2') format('woff2'),url('webfonts/393D78_8_0.woff') format('woff'),url('webfonts/393D78_8_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Nexa-Light';src: url('webfonts/393D78_9_0.eot');src: url('webfonts/393D78_9_0.eot?#iefix') format('embedded-opentype'),url('webfonts/393D78_9_0.woff2') format('woff2'),url('webfonts/393D78_9_0.woff') format('woff'),url('webfonts/393D78_9_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Nexa-RegularItalic';src: url('webfonts/393D78_A_0.eot');src: url('webfonts/393D78_A_0.eot?#iefix') format('embedded-opentype'),url('webfonts/393D78_A_0.woff2') format('woff2'),url('webfonts/393D78_A_0.woff') format('woff'),url('webfonts/393D78_A_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Nexa-Regular';src: url('webfonts/393D78_B_0.eot');src: url('webfonts/393D78_B_0.eot?#iefix') format('embedded-opentype'),url('webfonts/393D78_B_0.woff2') format('woff2'),url('webfonts/393D78_B_0.woff') format('woff'),url('webfonts/393D78_B_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Nexa-ThinItalic';src: url('webfonts/393D78_C_0.eot');src: url('webfonts/393D78_C_0.eot?#iefix') format('embedded-opentype'),url('webfonts/393D78_C_0.woff2') format('woff2'),url('webfonts/393D78_C_0.woff') format('woff'),url('webfonts/393D78_C_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Nexa-Thin';src: url('webfonts/393D78_D_0.eot');src: url('webfonts/393D78_D_0.eot?#iefix') format('embedded-opentype'),url('webfonts/393D78_D_0.woff2') format('woff2'),url('webfonts/393D78_D_0.woff') format('woff'),url('webfonts/393D78_D_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Nexa-XBoldItalic';src: url('webfonts/393D78_E_0.eot');src: url('webfonts/393D78_E_0.eot?#iefix') format('embedded-opentype'),url('webfonts/393D78_E_0.woff2') format('woff2'),url('webfonts/393D78_E_0.woff') format('woff'),url('webfonts/393D78_E_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'Nexa-XBold';src: url('webfonts/393D78_F_0.eot');src: url('webfonts/393D78_F_0.eot?#iefix') format('embedded-opentype'),url('webfonts/393D78_F_0.woff2') format('woff2'),url('webfonts/393D78_F_0.woff') format('woff'),url('webfonts/393D78_F_0.ttf') format('truetype');}
 